import { isNil, keyBy } from "lodash";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "contexts/AuthContext";
import { useDocumentManager } from "contexts/database/DocumentManagerContext";

const defaultTargetIdsFilter = (result) => Boolean(result);

export const useDocumentGroup = ({
  targetIds,
  targetWhere,
  queryKey,
  accessors,
  gcTime = 0,
  staleTime = Infinity,
  enabled,
  targetIdsFallback,
  targetIdsFilter = defaultTargetIdsFilter,
  // should only be used if necessary, this is v expensive
  showAll,
  initialShouldFetchFullGroup = true,
  lastSelected,
}) => {
  const { getAll, getWhere, read } = accessors;
  const { authLoaded } = useAuth();
  const { get: getFromCache, set: setInCache } = useDocumentManager();
  const [shouldFetchFullGroup, setShouldFetchFullGroup] = useState(
    initialShouldFetchFullGroup
  );

  // sorted for cache so no work is done if no IDs are changed
  const sortedTargetIds = useMemo(() => {
    if (!targetIds) {
      return targetIds;
    }

    return [...targetIds].sort();
  }, [targetIds]);

  const query = useQuery({
    queryKey: [
      queryKey,
      sortedTargetIds,
      targetWhere,
      showAll,
      targetIdsFallback,
      targetIdsFilter,
      authLoaded,
      shouldFetchFullGroup ? "" : lastSelected,
      shouldFetchFullGroup,
    ],
    queryFn: async () => {
      let result;

      if (showAll) {
        result = await getAll();
      } else if (!isNil(lastSelected) && !shouldFetchFullGroup) {
        const lastSelectedRes = await read(lastSelected);
        result = [lastSelectedRes].filter(targetIdsFilter);

        if (!result.length && targetIdsFallback) {
          result = await targetIdsFallback();
        }
      } else if (targetIds) {
        result = await Promise.all(
          targetIds.map((id) => {
            const cacheRes = getFromCache([queryKey, id]);

            if (cacheRes) {
              return cacheRes;
            }

            return read(id);
          })
        );
        result = result.filter(targetIdsFilter);

        if (!result.length && targetIdsFallback) {
          result = await targetIdsFallback();
        }
      } else if (targetWhere) {
        result = await getWhere(
          targetWhere.key,
          targetWhere.operator,
          targetWhere.value
        );
      } else {
        result = [];
      }

      result.forEach((doc) => {
        setInCache([queryKey, doc.id], () => doc);
      });

      return result;
    },
    placeholderData: [],
    gcTime,
    staleTime,
    enabled,
  });

  const dataMap = useMemo(() => keyBy(query.data, "id"), [query.data]);

  return {
    ...query,
    isFetching: query.isFetching || !authLoaded,
    dataMap,
    shouldFetchFullGroup,
    setShouldFetchFullGroup,
  };
};

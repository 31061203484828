import { createRoute } from "@tanstack/react-router";
import { rootRoute } from "components/Router/routes/rootRoute";
import { SurveySummary } from "./SurveySummary";

export const surveyRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "survey-summary/$version",
  component: SurveySummary,
  staticData: {
    shouldHideNavButton: true,
  },
});

import React, { useState } from "react";
import { SurveyQuestion } from "./SurveyQuestion";
import styles from "./changeLogSurvey.module.scss";
import { Section } from "components/Common/Section";
import { size } from "lodash";
import { Button } from "components/Common/Button";
import { Send } from "lucide-react";
import classnames from "classnames/bind";
import { latestSurveyVersion } from "./versions";

const classNameBuilder = classnames.bind(styles);

export const ChangeLogSurvey = ({
  survey,
  latestSurveyVersionRef,
  version,
  finished,
  onSubmit,
}) => {
  const { questions, description } = survey;
  const [surveyResponse, setSurveyResponse] = useState({});
  const submittable = size(surveyResponse) > 0;
  const [surveySubmitted, setSurveySubmitted] = useState(false);

  return (
    <Section
      className={styles["section"]}
      label={"Survey"}
      sublabel={"Anonymous and all questions are optional!"}
      expandable
      fullyCollapsible
      isSubSection={!finished}
      contrast
      disabled={finished}
      containerRef={latestSurveyVersionRef}
      initialExpanded={version === latestSurveyVersion}
      contentClassName={classNameBuilder("section-content", { finished })}
      headerSuffix={
        !finished && (
          <Button
            disabled={!submittable && !surveySubmitted}
            onClick={() => {
              onSubmit(surveyResponse);
              setSurveySubmitted(true);
            }}
            className={styles["section-button"]}
          >
            <Send
              style={{
                width: "16px",
                verticalAlign: "middle",
                marginRight: "5px",
                marginBottom: "2px",
              }}
            />
            Submit Response
          </Button>
        )
      }
    >
      {finished ? (
        <div className={styles["thanks"]}>Thank you for your response!</div>
      ) : (
        <div className={styles["content"]}>
          {description && (
            <div className={styles["description"]}>{description}</div>
          )}
          {questions && (
            <div className={styles["questions"]}>
              {questions.map((q, i) => (
                <SurveyQuestion
                  index={i}
                  question={q}
                  key={i}
                  value={surveyResponse[i]}
                  setValue={(v) =>
                    setSurveyResponse((prev) => ({ ...prev, [i]: v }))
                  }
                />
              ))}
            </div>
          )}
          {!finished && (
            <Button
              className={styles["footer-button"]}
              disabled={!submittable && !surveySubmitted}
              onClick={() => {
                onSubmit(surveyResponse);
                setSurveySubmitted(true);
              }}
            >
              <Send
                style={{
                  width: "16px",
                  verticalAlign: "middle",
                  marginRight: "5px",
                  marginBottom: "2px",
                }}
              />
              Submit Response
            </Button>
          )}
        </div>
      )}
    </Section>
  );
};

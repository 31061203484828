import { convertToDropdownOptions } from "components/Common/Roller/utils/rollerUtils";

export const SURVEY_TYPES = {
  DROPDOWN: "DROPDOWN",
  MULTI_SELECT: "MULTI_SELECT",
  SHORT_ANSWER: "SHORT_ANSWER",
};

export const ONE_TO_FIVE_OPTIONS = convertToDropdownOptions(
  ["", "5", "4", "3", "2", "1"],
  { shouldUseId: true }
);

export const YES_NO_MULTI_SELECT = convertToDropdownOptions(["Yes", "No"], {
  shouldUseId: true,
});
export const YES_NO_OPTIONS = convertToDropdownOptions(["", "Yes", "No"], {
  shouldUseId: true,
});
export const DAYS_OF_THE_WEEK_OPTIONS = convertToDropdownOptions(
  [
    "",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],
  { shouldUseId: true }
);

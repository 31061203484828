import React, { useState } from "react";
import styles from "./inputWithButton.module.scss";
import { Button } from "./Button";
import classnames from "classnames/bind";
import { handleEnterKey } from "../../utils/listeners";
import { noop } from "lodash";
import { Input } from "./Input";
import { Plus } from "lucide-react";

const classNameBuilder = classnames.bind(styles);

export const InputWithButton = ({
  placeholder,
  onChange,
  value,
  className,
  inputClassName,
  inputDisabled,
  buttonDisabled,
  buttonClassName,
  buttonChildren = <Plus />,
  onSubmit,
  containerClassName,
  containerRef,
  autoFocus,
  preventAutoFocusScroll,
  onInputFocus,
  onInputBlur,
  reverse,
  id,
  inputRef,
  suffix,
  children,
  ...rest
}) => {
  return (
    <div
      ref={containerRef}
      className={classNameBuilder("container", containerClassName, { reverse })}
      id={id}
    >
      <Button
        className={classNameBuilder("button", buttonClassName)}
        round
        disabled={buttonDisabled}
        onClick={onSubmit}
        {...rest}
      >
        {buttonChildren}
      </Button>
      {children}
      <Input
        className={classNameBuilder("input", inputClassName)}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={inputDisabled}
        onKeyUp={handleEnterKey((e) => {
          onSubmit(e);
        })}
        ref={inputRef}
        autoFocus={autoFocus}
        preventAutoFocusScroll={preventAutoFocusScroll}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
      />
      {suffix}
    </div>
  );
};

export const AutoInputWithButton = ({
  initialValue = "",
  onSubmit: propsOnSubmit = noop,
  onChange: propsOnChange = noop,
  buttonDisabled: propsDisabled,
  shouldDisableOnEmpty,
  shouldKeepOnSubmit,
  colorfulButton,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue);
  const onSubmit = (e) => {
    propsOnSubmit(value, e);

    if (!shouldKeepOnSubmit) {
      setValue("");
    }
  };

  const onChange = (e) => {
    propsOnChange(e);
    setValue(e.target.value);
  };

  return (
    <InputWithButton
      value={value}
      onSubmit={onSubmit}
      onChange={onChange}
      buttonDisabled={(shouldDisableOnEmpty && !value) || propsDisabled}
      colorful={colorfulButton && value}
      {...rest}
    />
  );
};

import React, { lazy, Suspense } from "react";
import styles from "./themeSettings.module.scss";
import settingsStyles from "../settingsDrawer.module.scss";
import { Section } from "components/Common/Section";
import { Dropdown } from "components/Common/Dropdown";
import {
  CUSTOM_DICE_KEY,
  diceThemesList,
  diceThemesMap,
  primaryThemesList,
  primaryThemesMap,
  secondaryThemesList,
  secondaryThemesMap,
} from "../../../constants/themes";
import { Loader } from "components/Common/Loader";
import { Button } from "components/Common/Button";
import classnames from "classnames/bind";

const SketchPicker = lazy(() =>
  import("react-color").then((m) => ({ default: m.SketchPicker }))
);

const classNameBuilder = classnames.bind(settingsStyles);

export const ThemeSettings = ({
  theme,
  setPrimaryTheme,
  setSecondaryTheme,
  setDiceTheme,
  newDiceColor,
  setNewDiceColor,
  setCustomDiceColor,
}) => (
  <Section
    label={"Themes"}
    isSubSection
    contentClassName={settingsStyles["setting-container"]}
  >
    <div className={styles["setting"]}>
      <div className={styles["sub-label"]}>Primary</div>
      <Dropdown
        items={primaryThemesList}
        selected={theme.primary}
        setSelected={setPrimaryTheme}
        itemMap={primaryThemesMap}
        containerClassName={styles["flex"]}
        tailClassName={styles["tail"]}
      />
    </div>
    <div className={styles["setting"]}>
      <div className={styles["sub-label"]}>Secondary</div>
      <Dropdown
        items={secondaryThemesList}
        selected={theme.secondary}
        setSelected={setSecondaryTheme}
        itemMap={secondaryThemesMap}
        containerClassName={styles["flex"]}
        tailClassName={styles["tail"]}
      />
    </div>
    <div className={styles["setting"]}>
      <div className={styles["sub-label"]}>Dice</div>
      <Dropdown
        items={diceThemesList}
        selected={theme.dice}
        setSelected={setDiceTheme}
        itemMap={diceThemesMap}
        containerClassName={styles["flex"]}
        tailClassName={styles["tail"]}
      />
    </div>
    {theme.dice === CUSTOM_DICE_KEY && (
      <div>
        <div className={styles["picker-container"]}>
          <Suspense fallback={<Loader />}>
            <SketchPicker
              className={styles["picker"]}
              color={newDiceColor}
              onChange={setNewDiceColor}
            />
          </Suspense>
          <Button
            hideBackground
            className={classNameBuilder(
              "button",
              styles["color-picker-button"]
            )}
            style={{
              backgroundColor: theme.customDiceColor,
            }}
            onClick={() => setCustomDiceColor(newDiceColor?.hex)}
          >
            Set Custom Dice Color
          </Button>
        </div>
      </div>
    )}
  </Section>
);

import { EXPANSIONS, EXPANSIONS_CONFIG } from "constants/expansions";
import { Tooltip } from "./Tooltip/Tooltip";
import { noop } from "lodash";

export const ExpansionLogo = ({ type, tooltipProps, hideTooltip, ...rest }) => {
  const { logo: Logo = noop, label } = EXPANSIONS_CONFIG[type] || {};

  return (
    <Logo {...rest}>
      {!hideTooltip && <Tooltip {...tooltipProps}>From: {label}</Tooltip>}
    </Logo>
  );
};

export const SarLogo = (props) => (
  <ExpansionLogo type={EXPANSIONS.SAR} {...props} />
);

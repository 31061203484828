import { useDatabase } from "./DatabaseContext";
import { useMainAccessors } from "./useMainAccessors";

export const SHIPS_COLLECTION = "ships";
const SHIPS_PATH = [SHIPS_COLLECTION];

export const useShipsAccessors = () => {
  const db = useDatabase();
  return useMainAccessors(db, SHIPS_PATH);
};

export const Submarine = ({ children, ...rest }) => (
  <svg
    id="a"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 22.27"
    width="24"
    height="24"
    transform="rotate(90)"
    {...rest}
  >
    <path
      d="M18.29,3.98c-1.05-1.05-4.7-1.57-9.41,3.14l-.38.38c-3.52,3.52-4.11,6.45-3.75,8.14l-.18.26H1.9l-.9.9,4.48,4.48.9-.9v-2.69l.26-.18c1.69.36,4.62-.23,8.14-3.75.07-.07.31-.31.38-.38,4.7-4.7,4.18-8.36,3.14-9.41Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="11.83"
      cy="10.44"
      r=".45"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="9.15"
      cy="13.13"
      r=".45"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="14.52"
      cy="7.75"
      r=".45"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M11.14,4.35c-.05-.06-.1-.13-.16-.19l-.13-.13c-1.01-1.01-2.66-1.01-3.67,0l-2.67,2.67c-1.01,1.01-1.01,2.66,0,3.67l.13.13c.14.14.29.25.45.35"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M3.91,1l-.43.43c-.58.58-.58,1.52,0,2.1l1.7,1.7"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    {children}
  </svg>
);

export const TelescopeB = ({ children, ...rest }) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-1.21 -2.105 24 24"
    width="24"
    height="24"
    {...rest}
  >
    <path
      d="M6.59,10.76l-3.91.83c-.5.11-.99-.2-1.11-.7l-.54-2.15c-.13-.54.17-1.08.69-1.27L15.23,3.04"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M11.29,9.79l5.4-1.18"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M12.79,18.79l-3.1-6.21"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M15.28,3.73c-.27-1.07.38-2.16,1.45-2.42l1.09-.27c.54-.13,1.08.19,1.21.73l1.51,6.06c.13.54-.19,1.08-.73,1.21l-1.09.27c-1.07.27-2.16-.38-2.42-1.45l-1.03-4.12Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M4.95,6.42l1.11,4.46"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M4.79,18.79l3.1-6.21"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="8.79"
      cy="10.79"
      r="2"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      fill="currentColor"
      d="M16.03,19.77c-.34,0-.61-.09-.79-.27s-.27-.44-.27-.79v-5.64c0-.34.09-.61.27-.79s.44-.27.79-.27h2.68c.56,0,1.03.08,1.42.24.39.16.69.39.9.69s.31.66.31,1.07c0,.45-.13.84-.39,1.17-.26.32-.61.55-1.05.67v-.19c.52.1.93.32,1.22.64.29.32.43.74.43,1.26,0,.68-.24,1.22-.73,1.61-.49.39-1.16.59-2.01.59h-2.78ZM16.9,15.1h1.44c.38,0,.66-.07.82-.2.17-.13.25-.33.25-.61s-.08-.47-.25-.6c-.17-.13-.44-.19-.82-.19h-1.44v1.6ZM16.9,18.29h1.65c.37,0,.65-.07.82-.21.17-.14.26-.35.26-.63,0-.29-.09-.51-.26-.65-.17-.14-.45-.21-.82-.21h-1.65v1.72Z"
    />
    {children}
  </svg>
);

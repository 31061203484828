import {
  ConfirmationModal,
  useConfirmationModal,
} from "components/Common/Portals/ConfirmationModal/ConfirmationModal";
import { Hand, ThumbsUp } from "lucide-react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "@tanstack/react-router";
import styles from "./navigatorContext.module.scss";
import classnames from "classnames/bind";
import { keys, size } from "lodash";
import { TimerContent } from "components/Common/TimerContent";
import { InlineEmailLink } from "components/Footer/FooterLink";
import { Loader } from "components/Common/Loader";

const classNameBuilder = classnames.bind(styles);

export const NavigatorContext = createContext();

export const NavigatorProvider = ({ children }) => {
  const location = useLocation();
  const [dirty, rawSetDirty] = useState(0);
  const [dirtyFields, setDirtyFields] = useState({});
  const dirtyRef = useRef();
  dirtyRef.current = dirty;

  const modalProps = useConfirmationModal();
  const { openModal, confirmationModalController, portalProps } = modalProps;

  const incrementDirty = useCallback(({ fieldId } = {}) => {
    rawSetDirty((prev) => {
      const val = prev + 1;
      dirtyRef.current = val;
      setDirtyFields((prev) => ({ ...prev, [fieldId]: true }));
      return val;
    });
  }, []);
  const decrementDirty = useCallback(({ fieldId } = {}) => {
    rawSetDirty((prev) => {
      const val = Math.max(prev - 1, 0);
      dirtyRef.current = val;
      setDirtyFields(({ [fieldId]: removedVal, ...rest }) => rest);
      return val;
    });
  }, []);

  // TODO: Figure out why this logic doesn't work properly
  // // prevent unload if dirtyRef is positive
  // useEffect(() => {
  //   window.onbeforeunload = (e) =>
  //     dirtyRef.current > 0 ? e.preventDefault() : false;

  //   return () => (window.onbeforeunload = noop);
  // }, [openModal]);

  // // on route change, reset dirty to 0 just in case someone changed their value late
  useEffect(() => {
    rawSetDirty(0);
    setDirtyFields({});
  }, [location]);
  useEffect(() => {
    const resolver = confirmationModalController.current;
    if (!dirty && resolver) {
      // mark the modal as closed -> then call resolver with true
      const timeout = window.setTimeout(() => {
        portalProps.setIsPortalComponentRendered(false);
        resolver(true);
      }, 1000);
      return () => window.clearTimeout(timeout);
    }
  }, [dirty, portalProps, confirmationModalController]);

  return (
    <NavigatorContext.Provider
      value={{ dirtyRef, incrementDirty, decrementDirty, openModal }}
    >
      <ConfirmationModal
        {...modalProps}
        title={dirty ? undefined : "Good to go!"}
        continueText={dirty ? undefined : "Continue"}
        continueClassName={classNameBuilder("continue-button", { dirty })}
        description={
          <div className={styles["description"]}>
            <div className={classNameBuilder("icon", { dirty })}>
              {dirty ? <Hand /> : <ThumbsUp />}
            </div>
            <span>
              {dirty ? (
                <div>
                  <TimerContent
                    fallback={
                      <>
                        <Loader />
                        <p>
                          This update is taking longer than expected. The
                          following fields still have unsaved changes.
                        </p>
                        <b>
                          <i>
                            {size(dirtyFields)
                              ? keys(dirtyFields).join(", ")
                              : "Unknown Fields"}
                          </i>
                        </b>
                        <p>
                          It's possible the update failed to save or we are
                          incorrectly detecting a change
                        </p>
                        <div style={{ textAlign: "left" }}>
                          If you're worried about losing this change:
                          <ol>
                            <li>Click Cancel</li>
                            <li>Back up your sheet using CTRL + S</li>
                            <li>Refresh the page</li>
                          </ol>
                        </div>
                        <p style={{ textAlign: "left" }}>
                          If this happens frequently, please submit a bug report
                          to <InlineEmailLink />
                        </p>
                      </>
                    }
                    delay={5000}
                  >
                    <Loader />
                    <p>
                      You have unsaved changes. Leaving will permanently delete
                      these changes.
                    </p>
                    <p>
                      Changes are saved automatically. Please wait a moment!
                    </p>
                  </TimerContent>
                </div>
              ) : (
                "Your changes have been saved successfully!"
              )}
            </span>
          </div>
        }
      />
      {children}
    </NavigatorContext.Provider>
  );
};

export const useNavigator = () => {
  const { dirtyRef, openModal, ...rest } = useContext(NavigatorContext);
  const rawNavigate = useNavigate();

  const navigate = useCallback(
    async (...args) => {
      if (dirtyRef.current > 0) {
        const res = await openModal();
        if (!res) {
          return;
        }
      }
      rawNavigate(...args);
    },
    [dirtyRef, openModal, rawNavigate]
  );

  return {
    dirtyRef,
    navigate,
    rawNavigate,
    openModal,
    ...rest,
  };
};

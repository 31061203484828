import { useInitializeAndCleanup } from "components/Common/hooks/useInitializeAndCleanup";
import { noop } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { usePortal } from "./PortalContext";

export const ChatHelperContext = createContext({
  name: "",
  setName: noop,
  portrait: "",
  setPortrait: noop,
  user: "",
  setUser: noop,
  userId: "",
  setUserId: noop,
  compendiumPortalPropsRef: { current: null },
});

export const ChatHelperProvider = (props) => {
  const compendiumPortalPropsRef = useRef();
  const [name, setName] = useState("");
  const [portrait, setPortrait] = useState("");
  const [user, setUser] = useState("");
  const [userId, setUserId] = useState("");

  const openCompendiumPortal = useCallback(() => {
    compendiumPortalPropsRef?.current?.setIsPortalComponentRendered(true);
  }, [compendiumPortalPropsRef]);

  const value = useMemo(
    () => ({
      name,
      setName,
      portrait,
      setPortrait,
      user,
      setUser,
      userId,
      setUserId,
      chatEntryBase: {
        name,
        portrait,
        user,
        userId,
      },
      compendiumPortalPropsRef,
      openCompendiumPortal,
    }),
    [name, openCompendiumPortal, portrait, user, userId]
  );

  return <ChatHelperContext.Provider value={value} {...props} />;
};

export const useCompendiumPortal = () => {
  const { compendiumPortalPropsRef } = useContext(ChatHelperContext);
  const portalProps = usePortal();

  useEffect(() => {
    compendiumPortalPropsRef.current = portalProps;
  }, [compendiumPortalPropsRef, portalProps]);

  return portalProps;
};

export const useChatEntryBaseUpdater = ({
  name: propName,
  portrait: propPortrait,
  user: propUser,
  userId: propUserId,
} = {}) => {
  const context = useContext(ChatHelperContext);
  const { setName, setPortrait, setUser, setUserId } = context;

  useInitializeAndCleanup(propName, setName);
  useInitializeAndCleanup(propPortrait, setPortrait);
  useInitializeAndCleanup(propUser, setUser);
  useInitializeAndCleanup(propUserId, setUserId);

  return context;
};
export const useChatEntryBase = () => {
  const { chatEntryBase } = useContext(ChatHelperContext);

  return chatEntryBase;
};

export const useChatHelper = () => useContext(ChatHelperContext);

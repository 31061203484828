import { EXPANSIONS } from "constants/expansions";
import { useEnabledExpansions } from "hooks/useGroupFFs";
import { concat } from "lodash";
import { useMemo } from "react";
import {
  PORTRAIT_IMAGES_BLOODLINES,
  PORTRAIT_IMAGES_ORIGINS,
  PORTRAIT_IMAGES_POSTS,
} from "../Portrait/portraitImages";
import { BASES_REACHES_PORTRAIT_BLOODLINES } from "../Portrait/expansionImages/baseReachesPortraits";
import {
  SAR_PORTRAIT_IMAGES_BLOODLINES,
  SAR_PORTRAIT_IMAGES_ORIGINS,
  SAR_PORTRAIT_IMAGES_POSTS,
} from "../Portrait/expansionImages/sarPortraits";
import { EMPTY_LIST } from "constants/defaults";
import {
  SAR_REACHES_PORTRAIT_IMAGES_BLOODLINES,
  SAR_REACHES_PORTRAIT_IMAGES_POSTS,
} from "../Portrait/expansionImages/sarReachesPortraits";

export const usePortraitImages = ({
  prefix = EMPTY_LIST,
  suffix = EMPTY_LIST,
} = {}) => {
  const { enabledExpansions } = useEnabledExpansions();

  return useMemo(() => {
    let bloodlineImages = PORTRAIT_IMAGES_BLOODLINES;
    let originImages = PORTRAIT_IMAGES_ORIGINS;
    let postImages = PORTRAIT_IMAGES_POSTS;

    if (enabledExpansions[EXPANSIONS.BASE_REACHES]) {
      bloodlineImages = concat(
        bloodlineImages,
        BASES_REACHES_PORTRAIT_BLOODLINES
      );
    }

    if (enabledExpansions[EXPANSIONS.SAR]) {
      bloodlineImages = concat(bloodlineImages, SAR_PORTRAIT_IMAGES_BLOODLINES);
      originImages = concat(originImages, SAR_PORTRAIT_IMAGES_ORIGINS);
      postImages = concat(postImages, SAR_PORTRAIT_IMAGES_POSTS);
    }

    if (enabledExpansions[EXPANSIONS.SAR_REACHES]) {
      bloodlineImages = concat(
        bloodlineImages,
        SAR_REACHES_PORTRAIT_IMAGES_BLOODLINES
      );
      postImages = concat(postImages, SAR_REACHES_PORTRAIT_IMAGES_POSTS);
    }

    return [
      ...prefix,
      ...bloodlineImages,
      ...originImages,
      ...postImages,
      ...suffix,
    ];
  }, [enabledExpansions, prefix, suffix]);
};

import { useTheme } from "../../contexts/ThemeContext";
import styles from "./modal.module.scss";
import classnames from "classnames/bind";
import { Portal } from "../../contexts/PortalContext";
import { Header } from "./Header";
import { Button } from "./Button";
import { X } from "./Icons";
import { useTransition } from "../../hooks/transition/useTransition";
import { noop } from "lodash";
import { FooterButtons } from "./FooterButtons/FooterButtons";

const classNameBuilder = classnames.bind(styles);

export const Modal = ({
  portalProps,
  title,
  subtitle,
  className,
  style,
  contentClassName,
  footerClassName,
  children,
  closable = true,
  header,
  headerProps,
  closePrefix,
  onClose = noop,

  hideCancel,
  hideSubmit,
  onFooterCancel,
  onFooterSubmit,

  cancelText,
  submitText,
  submitDisabled,
  permanent,

  contentRef,
}) => {
  const { isPortalComponentRendered, setIsPortalComponentRendered } =
    portalProps;
  const { themeClassNames } = useTheme();
  const [modalRef, isTransitionRendered, isTransitionEntering] = useTransition(
    isPortalComponentRendered
  );

  return (
    <Portal portalProps={portalProps}>
      {isTransitionRendered || permanent ? (
        <div className={styles["modal-container"]}>
          <div
            ref={modalRef}
            style={style}
            className={classNameBuilder(
              "modal",
              ...themeClassNames,
              {
                opened: isTransitionEntering,
                hidden: permanent ? !isTransitionRendered : false,
              },
              className
            )}
          >
            {header !== undefined ? (
              header
            ) : (
              <Header
                title={title}
                subtitle={subtitle}
                titleClassName={styles["title"]}
                {...headerProps}
              >
                {closable && (
                  <>
                    {closePrefix}
                    <Button
                      dark
                      large
                      icon
                      onClick={() => {
                        onClose();
                        setIsPortalComponentRendered(false);
                      }}
                    >
                      <X className={styles["close"]} />
                    </Button>
                  </>
                )}
              </Header>
            )}
            <div
              className={classNameBuilder("content", contentClassName)}
              ref={contentRef}
            >
              {children}
              {(onFooterCancel || onFooterSubmit) && (
                <FooterButtons
                  className={footerClassName}
                  onCancel={
                    hideCancel
                      ? null
                      : onFooterCancel ||
                        (() => setIsPortalComponentRendered(false))
                  }
                  onSubmit={
                    hideSubmit
                      ? null
                      : onFooterSubmit ||
                        (() => setIsPortalComponentRendered(true))
                  }
                  cancelText={cancelText}
                  submitText={submitText}
                  submitDisabled={submitDisabled}
                />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </Portal>
  );
};

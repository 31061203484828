import { Submarine } from "components/Common/Icons/Submarine";
import { TelescopeB } from "components/Common/Icons/TelescopeB";
import { TelescopeS } from "components/Common/Icons/TelescopeS";
import { BulletList } from "components/Common/List/BulletList";
import { values } from "lodash";
import { CloudLightning, PawPrint, Plane } from "lucide-react";

export const EXPANSIONS = {
  BASE_REACHES: "BASE_REACHES",
  SAR: "SAR",
  SAR_AIR: "SAR_AIR",
  SAR_SUB: "SAR_SUB",
  SAR_REACHES: "SAR_REACHES",
  SAR_NON_STANDARD: "SAR_NON_STANDARD",
};

export const EXPANSIONS_VALUES = values(EXPANSIONS);

export const EXPANSIONS_CONFIG = {
  [EXPANSIONS.BASE_REACHES]: {
    label: "Reaches (Base game)",
    logo: TelescopeB,
    shouldShowGroupExpansionEntry: true,
    groupExpansionTooltipContent: (
      <>
        <div>When enabled adds the following:</div>
        <br />
        <u>Bloodlines</u>
        <BulletList items={["Corron", "DaWheh-Whe"]} />
        <br />
        <u>Bites</u>
        <BulletList items={["Chainwheels"]} />
        <br />
        <u>Engines</u>
        <BulletList items={["Steam Exchange"]} />
      </>
    ),
  },
  [EXPANSIONS.SAR]: {
    label: "Storm and Root",
    logo: CloudLightning,
    shouldShowGroupExpansionEntry: true,
    shouldCheckSarPurchase: true,
    groupExpansionLabel: (
      <>
        <CloudLightning style={{ marginBottom: "1px" }} />
        <span>Storm and Root,</span>
        <Plane style={{ marginBottom: "1px" }} />
        <span>Airships, and</span>
        <Submarine style={{ marginBottom: "1px" }} />
        <span>Submersibles</span>
      </>
    ),
    groupExpansionTooltipContent: (
      <>
        <div>When enabled adds the following:</div>
        <br />
        <u>Character Options</u>
        <BulletList
          items={[
            "1 bloodline",
            "5 origins",
            "12 posts",
            "Extra feats for all existing backgrounds",
          ]}
        />
        <br />
        <u>Ship Options</u>
        <BulletList
          items={[
            "New designs and ratings for submersibles and airships",
            "Extra options for existing Designs, Fittings, and Undercrew",
          ]}
        />
        <br />
        <u>Mechanics</u>
        <BulletList
          items={[
            "Scrutiny (found on the Ships page)",
            "Pressure Gauge (found on the GMs page)",
          ]}
        />
      </>
    ),
  },
  [EXPANSIONS.SAR_AIR]: {
    label: "Storm and Root - Airship",
    logo: Plane,
  },
  [EXPANSIONS.SAR_SUB]: {
    label: "Storm and Root - Submarine",
    logo: Submarine,
  },
  [EXPANSIONS.SAR_REACHES]: {
    label: "Reaches (Storm and Root)",
    logo: TelescopeS,
    shouldShowGroupExpansionEntry: true,
    shouldCheckSarPurchase: true,
    groupExpansionTooltipContent: (
      <>
        <div>When enabled adds the following:</div>
        <br />
        <u>Bloodlines</u>
        <BulletList items={["Carcharbora"]} />
        <br />
        <u>Posts</u>
        <BulletList items={["Petrou"]} />
      </>
    ),
  },
  [EXPANSIONS.SAR_NON_STANDARD]: {
    label: "Non-Standard Bloodlines (Storm and Root)",
    logo: PawPrint,
    shouldShowGroupExpansionEntry: true,
    shouldCheckSarPurchase: true,
    groupExpansionTooltipContent: (
      <>
        <div>When enabled adds aspects to the following Bloodlines:</div>
        <br />
        <BulletList
          items={[
            "Ardent",
            "Ektus",
            "Gau",
            "Ironbound",
            "Ketra",
            "Mothryn",
            "Tzelicrae",
            "Itzenko",
          ]}
        />
      </>
    ),
  },
};

import { Modal } from "components/Common/Modal";
import React from "react";
import styles from "./previewImageModal.module.scss";
import {
  BaseImage,
  Image,
  IMAGE_POSITIONS,
} from "components/Common/Image/Image";

export const PreviewImageModal = ({
  value,
  portalProps,
  title = "",
  shouldRenderBackground,
  ...rest
}) => {
  const ImageComponent = shouldRenderBackground ? Image : BaseImage;
  return (
    <Modal portalProps={portalProps} title={title}>
      <ImageComponent
        src={value}
        alt="portrait"
        className={styles["big-portrait"]}
        position={IMAGE_POSITIONS.PORTRAIT}
        contain
        {...rest}
      />
    </Modal>
  );
};

import { useDatabase } from "./DatabaseContext";
import { useMainAccessors } from "./useMainAccessors";

export const GROUPS_COLLECTION = "wildsea-groups";
const GROUPS_PATH = [GROUPS_COLLECTION];

export const useGroupsAccessors = () => {
  const db = useDatabase();
  return useMainAccessors(db, GROUPS_PATH);
};

import React from "react";
import { Orbit } from "./Icons";
import styles from "./loader.module.scss";
import classnames from "classnames/bind";

const classNameBuilder = classnames.bind(styles);

export const Loader = ({ className, style }) => {
  return (
    <Orbit className={classNameBuilder("loader", className)} style={style} />
  );
};

export const LoaderWithText = ({
  className,
  style,
  loaderClassName,
  loaderStyle,
  textClassName,
  noPadding,
  centered,
  children,
}) => {
  return (
    <div
      className={classNameBuilder(
        "loader-with-text",
        { "no-padding": noPadding, centered },
        className
      )}
      style={style}
    >
      <Loader className={loaderClassName} style={loaderStyle} />
      <span className={classNameBuilder("text", textClassName)}>
        {children}
      </span>
    </div>
  );
};

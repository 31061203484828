import { useDatabase } from "./DatabaseContext";
import { useMainAccessors } from "./useMainAccessors";

const SURVEYS_COLLECTION = "surveys";
const SURVEYS_PATH = [SURVEYS_COLLECTION];

export const useSurveysAccessors = () => {
  const db = useDatabase();
  return useMainAccessors(db, SURVEYS_PATH);
};

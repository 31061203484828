import { Button } from "components/Common/Button";
import styles from "./confirmationModal.module.scss";
import { Modal } from "components/Common/Modal";
import { usePortal } from "contexts/PortalContext";
import { useCallback, useEffect, useRef } from "react";
import classnames from "classnames/bind";

const classNameBuilder = classnames.bind(styles);

/**
 * when using, must pass portalProps and confirmationModalController
 * to a ConfirmationModal instance.
 *
 * When you call openModal, it will return a promise that resolves true when
 * the user clicks the "Confirm" button and false otherwise
 *
 * This can be used to confirm an action before moving on.
 */
export const useConfirmationModal = () => {
  const portalProps = usePortal();
  const confirmationModalController = useRef();
  const portalPropsRef = useRef(portalProps);
  portalPropsRef.current = portalProps;

  const openModal = useCallback(() => {
    portalPropsRef.current.setIsPortalComponentRendered(true);

    return new Promise((resolve) => {
      confirmationModalController.current = resolve;
    });
  }, []);

  return {
    portalProps,
    openModal,
    confirmationModalController,
  };
};

export const ConfirmationModal = ({
  portalProps,
  confirmationModalController,
  title = "Are you sure?",
  description = "You have unsaved changes. Are you sure you want to leave?",
  cancelText = "Cancel",
  continueText = "Leave without saving",
  cancelClassName,
  continueClassName,
}) => {
  const { isPortalComponentRendered, setIsPortalComponentRendered } =
    portalProps;

  useEffect(() => {
    // if the portal is closed AND the portal has been prompted, mark the resolution as false
    if (!isPortalComponentRendered && confirmationModalController.current) {
      confirmationModalController.current(false);
      confirmationModalController.current = null;
    }
  }, [confirmationModalController, isPortalComponentRendered]);

  return (
    <Modal
      portalProps={portalProps}
      closeDrawer={() => setIsPortalComponentRendered(false)}
      title={title}
      contentClassName={styles["container"]}
    >
      <div className={styles["description"]}>{description}</div>
      <div className={styles["buttons"]}>
        <Button
          className={classNameBuilder("cancel", cancelClassName)}
          onClick={() => setIsPortalComponentRendered(false)}
        >
          {cancelText}
        </Button>
        <Button
          onClick={() => {
            const resolver = confirmationModalController.current;
            // mark the modal as closed -> then call resolver with true
            setIsPortalComponentRendered(false);
            if (resolver) {
              resolver(true);
            }
          }}
          className={classNameBuilder("continue", continueClassName)}
        >
          {continueText}
        </Button>
      </div>
    </Modal>
  );
};

import { Dot } from "lucide-react";
import React from "react";
import styles from "./bulletList.module.scss";
import classnames from "classnames/bind";
import {
  BULLET_LIST_ITEM_TYPE,
  BULLET_LIST_ITEM_TYPE_CONFIG,
} from "./bulletListConsts";

const classNameBuilder = classnames.bind(styles);

export const BulletList = ({
  items,
  inherit,
  className,
  itemContentClassName,
  itemBulletClassName,
}) => (
  <ul className={classNameBuilder("container", className, { inherit })}>
    {getBulletList(items, {
      className,
      itemContentClassName,
      itemBulletClassName,
    })}
  </ul>
);

export const getBulletList = (
  items,
  { className, itemContentClassName, itemBulletClassName, depth } = {}
) =>
  items?.map((item, index) => {
    let Bullet = Dot;
    let content = item;
    let type = item?.type;
    let typeConfig = BULLET_LIST_ITEM_TYPE_CONFIG[type] || {};
    let subItems = item?.items;

    if (type && BULLET_LIST_ITEM_TYPE[type]) {
      Bullet = typeConfig?.icon || Dot;
      content = item.text || "";
    }

    return (
      <React.Fragment key={index}>
        <li
          key={index}
          className={classNameBuilder("item-container", typeConfig?.style)}
        >
          <Bullet
            className={classNameBuilder("bullet", itemBulletClassName, {
              dot: Bullet === Dot,
            })}
          ></Bullet>
          <div className={itemContentClassName}>{content}</div>
        </li>
        {subItems && (
          <ul
            className={classNameBuilder(
              "container",
              "inner-container",
              className
            )}
          >
            {getBulletList(subItems, {
              itemContentClassName,
              itemBulletClassName,
            })}
          </ul>
        )}
      </React.Fragment>
    );
  });

import carcharboraP from "./carcharboraP.png";
import petrouP from "./petrouP.png";

export const SAR_REACHES_PORTRAIT_IMAGES_BLOODLINES = [carcharboraP];
export const SAR_REACHES_PORTRAIT_IMAGES_POSTS = [petrouP];

export const SAR_REACHES_PORTRAIT_IMAGES = [
  ...SAR_REACHES_PORTRAIT_IMAGES_BLOODLINES,
  ...SAR_REACHES_PORTRAIT_IMAGES_POSTS,
];

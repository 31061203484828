import styles from "./textarea.module.scss";
import classnames from "classnames/bind";

const classNameBuilder = classnames.bind(styles);

export const Textarea = ({ className, containerRef, height, ...rest }) => {
  return (
    <textarea
      ref={containerRef}
      className={classNameBuilder("textarea", className)}
      style={{ height }}
      {...rest}
    />
  );
};

import { patreonLink, supportEmail } from "constants/contactInfo";
import { ExternalLink, Mail } from "lucide-react";
import classnames from "classnames/bind";
import styles from "./footerLink.module.scss";
import { Patreon } from "components/Common/Icons/Patreon";

const classNameBuilder = classnames.bind(styles);

export const FooterLink = ({ children, href, className }) => (
  <a
    className={classNameBuilder("link", "fit-width", className)}
    target="_blank"
    href={href}
    rel="noreferrer"
  >
    {children}
  </a>
);
export const PatreonLink = () => (
  <FooterLink href={patreonLink}>
    <Patreon />
    Support this app on Patreon!
  </FooterLink>
);

export const GeneralLink = ({ className, href, children }) => (
  <FooterLink href={href} className={className}>
    <ExternalLink />
    {children}
  </FooterLink>
);

export const EmailLink = ({ className, iconClassName }) => (
  <FooterLink href={`mailto: ${supportEmail}`} className={className}>
    <Mail className={iconClassName} /> {supportEmail}
  </FooterLink>
);

export const InlineEmailLink = (props) => (
  <EmailLink
    className={styles["inline-email"]}
    iconClassName={styles["inline-email-icon"]}
    {...props}
  />
);

import React, { forwardRef, useEffect, useRef } from "react";
import styles from "./input.module.scss";
import classnames from "classnames/bind";

const classNameBuilder = classnames.bind(styles);

export const Input = forwardRef(
  (
    { className, autoFocus, preventAutoFocusScroll, presentation, ...rest },
    ref
  ) => {
    const inputRef = useRef();
    const finalRef = ref || inputRef;
    useEffect(() => {
      if (autoFocus) {
        finalRef?.current?.focus({ preventScroll: preventAutoFocusScroll });
      }
    }, [autoFocus, finalRef, preventAutoFocusScroll]);
    return (
      <input
        ref={finalRef}
        className={classNameBuilder("input", { presentation }, className)}
        {...rest}
      />
    );
  }
);

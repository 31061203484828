import { createRouter, createRoute, Navigate } from "@tanstack/react-router";
import { rootRoute } from "./routes/rootRoute";
import { characterRoute } from "components/Characters/constants/charactersRoutes";
import { groupRoute } from "components/Groups/constants/groupsRoutes";
import { shipRoute } from "components/Ships/constants/shipsRoutes";
import { gameMasterRoute } from "components/GameMasters/constants/gameMasterRoutes";
import { JoinGroup } from "components/Groups/JoinGroup";
import { compendiumRoute } from "components/Compendium/consts/routes";
import { GROUPS_PATH } from "components/Groups/constants/groupsConsts";
import { surveyRoute } from "components/Surveys/surveyRoutes";

const rootRouteChildren = [
  groupRoute,
  characterRoute,
  shipRoute,
  gameMasterRoute,
  createRoute({
    getParentRoute: () => rootRoute,
    path: "join-group/$groupId",
    component: JoinGroup,
    staticData: {
      shouldHideNavButton: true,
    },
  }),
  surveyRoute,
  compendiumRoute,
  createRoute({
    getParentRoute: () => rootRoute,
    path: "/",
    component: () => <Navigate to={`/${GROUPS_PATH}`} />,
  }),
];

const routeTree = rootRoute.addChildren(rootRouteChildren);

export const router = createRouter({
  routeTree,
  defaultNotFoundComponent: () => (
    <div>
      <h1 style={{ padding: "30px 40px" }}>Page not found!</h1>
    </div>
  ),
});

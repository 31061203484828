import annoyed from "./annoyed.png";
import mad from "./mad.png";
import blushing from "./blushing.png";
import confused from "./confused.png";
import content from "./content.png";
import cowboyFrown from "./cowboyFrown.png";
import cowboyHappy from "./cowboyHappy.png";
import cryChar2 from "./cryChar2.png";
import crying from "./crying.png";
import cursing from "./cursing.png";
import happyCry from "./happyCry.png";
import nauseousCry from "./nauseousCry.png";
import nervous from "./nervous.png";
import noThoughts from "./noThoughts.png";
import sadThoughts from "./sadThoughts.png";
import shock from "./shock.png";
import smirk from "./smirk.png";
import someThoughts from "./someThoughts.png";
import sophisticated from "./sophisticated.png";
import sunglasses from "./sunglasses.png";
import sunglassSmile from "./sunglassSmile.png";
import normalShock from "./normalShock.png";
import normalSmile from "./normalSmile.png";
import normalSad from "./normalSad.png";
import normalMad from "./normalMad.png";
import sunglassesFrown from "./sunglassesFrown.png";
import cowboyNeutral from "./cowboyNeutral.png";

export const STICKERS = {
  normalSmile,
  normalSad,
  normalShock,
  nervous,
  annoyed,
  confused,
  normalMad,
  content,
  blushing,
  cryChar2,
  crying,
  happyCry,
  nauseousCry,
  shock,
  someThoughts,
  noThoughts,
  sadThoughts,
  mad,
  cursing,
  smirk,
  sophisticated,
  sunglasses,
  sunglassSmile,
  sunglassesFrown,
  cowboyNeutral,
  cowboyHappy,
  cowboyFrown,
};

export const STICKERS_LIST = Object.values(STICKERS);

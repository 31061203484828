import augurP from "./augurP.png";
import cannoneerP from "./cannoneerP.png";
import diverP from "./diverP.png";
import heartskavoP from "./heartskavoP.png";
import itzenkoP from "./itzenkoP.png";
import kitesailorP from "./kitesailorP.png";
import kosmerP from "./kosmerP.png";
import marketeerP from "./marketeerP.png";
import ravellerP from "./ravellerP.png";
import smugglerP from "./smugglerP.png";
import stowawayP from "./stowawayP.png";
import submergedP from "./submergedP.png";
import swarmjackP from "./swarmjackP.png";
import thornP from "./thornP.png";
import windwardP from "./windwardP.png";
import zealotP from "./zealotP.png";

export const SAR_PORTRAIT_IMAGES_BLOODLINES = [itzenkoP];
export const SAR_PORTRAIT_IMAGES_ORIGINS = [
  kosmerP,
  heartskavoP,
  stowawayP,
  submergedP,
  windwardP,
];
export const SAR_PORTRAIT_IMAGES_POSTS = [
  augurP,
  cannoneerP,
  diverP,
  kitesailorP,
  marketeerP,
  ravellerP,
  smugglerP,
  swarmjackP,
  thornP,
  zealotP,
];

export const SAR_PORTRAIT_IMAGES = [
  ...SAR_PORTRAIT_IMAGES_BLOODLINES,
  ...SAR_PORTRAIT_IMAGES_ORIGINS,
  ...SAR_PORTRAIT_IMAGES_POSTS,
];

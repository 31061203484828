import styles from "./pickOrUploadModal.module.scss";
import classnames from "classnames/bind";
import { useEffect, useState } from "react";
import { Modal } from "../Modal";
import { Button } from "../Button";
import { BaseImage, Image } from "../Image/Image";
import { AutoInputWithButton } from "../InputWithButton";
import { Upload } from "lucide-react";
import { Input } from "../Input";
import { get } from "lodash";
import { uploadFileToCDN } from "lib/imageUtils";
import { LoaderWithText } from "../Loader";

const classNameBuilder = classnames.bind(styles);

export const PickOrUploadModal = ({
  portalProps,
  onSubmit,
  defaultImages,
  title = "Pick or Upload",
  initialValue,
  imageContainerClassName,
  imageClassName,
  buttonClassName,
  imageProps,
  defaultName = "Default",
  shouldDisableBackground,
  disableAutoFocusUpload,
}) => {
  const { setIsPortalComponentRendered } = portalProps;
  const [newImageUrl, setNewImageUrl] = useState(initialValue);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setNewImageUrl(initialValue);
  }, [initialValue, portalProps.isPortalComponentRendered]);

  const ImageComponent = shouldDisableBackground ? BaseImage : Image;

  return (
    <Modal
      portalProps={portalProps}
      closeDrawer={() => setIsPortalComponentRendered(false)}
      title={title}
      contentClassName={styles["container"]}
      onFooterCancel={() => setIsPortalComponentRendered(false)}
      onFooterSubmit={() => {
        setIsPortalComponentRendered(false);
        onSubmit(newImageUrl);
      }}
      footerClassName={styles["footer"]}
      submitDisabled={!newImageUrl}
    >
      <div className={styles["preview"]}>
        {newImageUrl && (
          <ImageComponent
            className={classNameBuilder(
              "preview-image-container",
              imageContainerClassName
            )}
            imageClassName={classNameBuilder("preview-image", imageClassName)}
            src={newImageUrl || ""}
            alt="selected"
            contain
            {...imageProps}
          />
        )}
        {!newImageUrl && (
          <div className={classNameBuilder("or", "prompt")}>
            No Image Selected
          </div>
        )}
      </div>
      <div className={styles["options-container"]}>
        <div className={styles["title"]}>Upload your own</div>
        <AutoInputWithButton
          placeholder="Enter an image URL"
          onSubmit={(v) => {
            setNewImageUrl(v);
          }}
          reverse
          autoFocus={!disableAutoFocusUpload}
          buttonChildren={<Upload className={styles["upload-icon"]} />}
          containerClassName={styles["upload-input"]}
        />
        <div className={classNameBuilder("or")}>OR</div>
        <div className={classNameBuilder("title")}>Upload a File</div>
        {isUploading ? (
          <LoaderWithText centered>Please wait a moment...</LoaderWithText>
        ) : (
          <Input
            className={classNameBuilder("upload-input")}
            disabled={isUploading}
            type="file"
            accept="image/png, image/jpg"
            onChange={async (e) => {
              let file = get(e, "target.files.0");
              if (!file) {
                return;
              }

              let cloudinaryUrl;
              setIsUploading(true);
              try {
                cloudinaryUrl = await uploadFileToCDN(file);
              } catch (err) {
                setIsUploading(false);
                console.error(err);
                alert(err.message);

                return;
              }

              setIsUploading(false);
              setNewImageUrl(cloudinaryUrl);
            }}
          />
        )}

        {defaultImages && (
          <>
            <div className={classNameBuilder("or")}>OR</div>
            <div className={styles["title"]}>Pick A {defaultName}</div>
            <div className={styles["grid"]}>
              {defaultImages.map((p, i) => {
                return (
                  <Button
                    key={i}
                    icon
                    onClick={() => {
                      setNewImageUrl(p);
                    }}
                    className={classNameBuilder("button", buttonClassName)}
                  >
                    <ImageComponent
                      className={classNameBuilder("image")}
                      src={p}
                      alt="default"
                      {...imageProps}
                    />
                  </Button>
                );
              })}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

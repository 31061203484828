import {
  generateSecondaryThemeClassName,
  useTheme,
} from "../../contexts/ThemeContext";
import { Button } from "./Button";
import styles from "./radio.module.scss";
import classnames from "classnames/bind";
import { Circle } from "./Shape/Circle";
import { Slash } from "./Shape/Slash";
import { useDisabledHoverOnClick } from "hooks/useDisabledHoverOnClick";
import { noop } from "lodash/fp";
import { getWrap } from "lib/arrayUtils";
import { indexOf } from "lodash";

const classNameBuilder = classnames.bind(styles);

export const RADIO_STATES = {
  EMPTY: 0,
  MARKED: 1,
  BURNED: 2,
};

export const getRadioValue = (value) =>
  value ? RADIO_STATES.MARKED : RADIO_STATES.EMPTY;

export const Radio = ({
  value,
  className,
  containerClassName,
  themeColor,
  hovered: hoveredProp,
  onMouseEnter: onMouseEnterProp = noop,
  onMouseLeave: onMouseLeaveProp = noop,
  onClick: onClickProp = noop,
  states = [RADIO_STATES.EMPTY, RADIO_STATES.MARKED],
  delay,
  children,
  burnedIcon,
  label,
  colorless,
  presentation,
  disableHoveredAnimation,
  ...rest
}) => {
  const {
    hovered: hoveredState,
    onMouseEnter,
    onMouseLeave,
    onClick,
  } = useDisabledHoverOnClick();
  const { themeClassNames } = useTheme();
  const colorClassNames = themeColor
    ? generateSecondaryThemeClassName(themeColor)
    : themeClassNames;
  const nextValue = getWrap(states, indexOf(states, value));
  const empty = value === RADIO_STATES.EMPTY;
  const marked = value === RADIO_STATES.MARKED;
  const burned = value === RADIO_STATES.BURNED;
  const nextEmpty = nextValue === RADIO_STATES.EMPTY;
  const nextMarked = nextValue === RADIO_STATES.MARKED;
  const nextBurned = nextValue === RADIO_STATES.BURNED;
  const hasHoveredState = hoveredProp ?? hoveredState;
  const hovered = !disableHoveredAnimation && hasHoveredState;

  const classNameProps = {
    hovered,
    empty,
    marked,
    burned,
    "next-empty": nextEmpty,
    "next-marked": nextMarked,
    "next-burned": nextBurned,
  };

  return (
    <div
      className={classNameBuilder("container", containerClassName, {
        presentation,
      })}
      onMouseEnter={(e) => onMouseEnter(e) || onMouseEnterProp(e)}
      onMouseLeave={(e) => onMouseLeave(e) || onMouseLeaveProp(e)}
      onClick={(e) => onClick(e) || onClickProp(e, nextValue)}
    >
      <Button
        className={classNameBuilder("button", ...colorClassNames, className)}
        disableMovement
        disableHover
        hideBackground
        {...rest}
      >
        {burnedIcon ?? (
          <>
            <Slash className={classNameBuilder("slash", classNameProps)} />
            <Slash className={classNameBuilder("slash-back", classNameProps)} />
          </>
        )}
        <Circle
          className={classNameBuilder("circle-svg-animated", { colorless })}
          circleClassName={styles["circle-animated"]}
          progress={marked ? 1 : 0}
          delay={delay}
        />
        <Circle
          className={classNameBuilder("circle-svg-normal", { colorless })}
        />
        <div
          className={classNameBuilder(
            "big-dot",
            { colorless },
            ...colorClassNames,
            classNameProps
          )}
        />
      </Button>
      {label}
    </div>
  );
};

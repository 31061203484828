import React from "react";
import { SURVEY_TYPES } from "./surveys";
import { Dropdown } from "components/Common/Dropdown";
import { Textarea } from "components/Common/Textarea";
import { getRadioValue, Radio } from "components/Common/Radio";
import styles from "./surveyQuestion.module.scss";

export const SurveyQuestion = ({ value, setValue, question }) => {
  const {
    options,
    limit = 100,
    type = SURVEY_TYPES.DROPDOWN,
    label,
    description,
  } = question;
  let content = null;

  if (type === SURVEY_TYPES.DROPDOWN) {
    content = (
      <Dropdown
        containerClassName={styles["dropdown"]}
        tailClassName={styles["dropdown-tail"]}
        selected={value}
        setSelected={(v) => setValue(v)}
        items={options}
      />
    );
  } else if (type === SURVEY_TYPES.SHORT_ANSWER) {
    content = (
      <div>
        <Textarea
          className={styles["textarea"]}
          value={value}
          rows={Math.ceil(limit / 100)}
          onChange={(e) => setValue(e.target.value.substr(0, limit))}
        />
        <div>
          {value?.length || 0} / {limit} characters
        </div>
      </div>
    );
  } else if (type === SURVEY_TYPES.MULTI_SELECT) {
    content = (
      <div className={styles["multi-container"]}>
        {options.map((o) => (
          <Radio
            key={o.id}
            label={o.label}
            value={getRadioValue(value?.[o.id])}
            onClick={() => setValue({ ...value, [o.id]: !value?.[o.id] })}
          />
        ))}
      </div>
    );
  }
  return (
    <div className={styles["container"]}>
      <u>{label}</u>
      {description && <i>{description}</i>}
      {content}
    </div>
  );
};

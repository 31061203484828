import { useEffect, useState } from "react";

export const TimerContent = ({
  children,
  initialHidden,
  fallback = null,
  delay,
}) => {
  const [visible, setVisible] = useState(!initialHidden);

  useEffect(() => {
    const id = setTimeout(() => setVisible((prev) => !prev), delay);

    return () => clearTimeout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return visible ? children : fallback;
};

export const TelescopeS = ({ children, ...rest }) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-1.21 -2.105 24 24"
    width="24"
    height="24"
    {...rest}
  >
    <path
      d="M6.59,10.76l-3.91.83c-.5.11-.99-.2-1.11-.7l-.54-2.15c-.13-.54.17-1.08.69-1.27L15.23,3.04"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M11.29,9.79l5.4-1.18"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M12.79,18.79l-3.1-6.21"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M15.28,3.73c-.27-1.07.38-2.16,1.45-2.42l1.09-.27c.54-.13,1.08.19,1.21.73l1.51,6.06c.13.54-.19,1.08-.73,1.21l-1.09.27c-1.07.27-2.16-.38-2.42-1.45l-1.03-4.12Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M4.95,6.42l1.11,4.46"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M4.79,18.79l3.1-6.21"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="8.79"
      cy="10.79"
      r="2"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      fill="currentColor"
      d="M17.87,19.9c-.3,0-.61-.02-.92-.07s-.62-.11-.91-.19c-.29-.08-.55-.18-.78-.29-.2-.09-.34-.22-.42-.38-.08-.16-.11-.33-.09-.5.02-.17.08-.33.19-.46.1-.13.24-.23.41-.27s.36-.02.57.07c.28.13.59.24.95.31.35.08.69.12,1,.12.48,0,.81-.07,1.01-.2s.29-.29.29-.48c0-.17-.07-.3-.2-.41s-.38-.19-.72-.26l-1.29-.28c-.71-.15-1.24-.41-1.58-.78s-.51-.83-.51-1.41c0-.39.08-.74.24-1.04.16-.31.38-.57.67-.79.29-.22.63-.39,1.03-.52s.85-.19,1.33-.19c.38,0,.77.04,1.16.13s.73.22,1.02.38c.17.09.29.21.37.37.08.16.11.32.09.48-.02.16-.08.3-.18.43-.1.12-.23.21-.4.25s-.36.01-.59-.08c-.22-.09-.46-.17-.73-.22-.26-.05-.52-.08-.77-.08-.27,0-.5.03-.69.1-.19.07-.34.16-.43.27-.1.12-.15.25-.15.41s.07.28.2.39c.13.11.37.19.7.26l1.28.27c.71.15,1.24.41,1.59.76.35.36.53.82.53,1.38,0,.38-.08.72-.23,1.03-.15.3-.38.57-.67.79s-.63.39-1.03.51-.85.18-1.35.18Z"
    />
    {children}
  </svg>
);

import { InitialLoadChangeLog } from "components/ChangeLog/ChangeLog";
import { Tutorial } from "components/Tutorial/Tutorial";
import React from "react";

export const PopupManager = () => {
  return (
    <>
      <Tutorial />
      <InitialLoadChangeLog />
    </>
  );
};
